import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql } from "gatsby" // to query for image data
import { GatsbyImage } from "gatsby-plugin-image"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="About us" />

    <div className="container mx-auto pt-6 pb-48   px-6" id="about">
      <h1 className="my-4 text-4xl font-medium leading-snug tracking-wider">
        About us
      </h1>

      <blockquote className="relative p-4 my-4 text-xl italic border-l-4">
        <p className="mb-4">
          <i className="fas fa-quote-left text-4xl"></i>
          My dad - an old and experienced sea captain, told me now and then
          during my youth that he needed a tool to quickly make load plans out
          of packing manifests. The ones that existed were not good enough, and
          could not produce realistic load plans. Many years later after
          graduating from university I thought well, lets give it a try... I
          thought I now knew enough to create something my dad would use.
        </p>
        <cite className="flex items-center">
          <GatsbyImage
            image={data.profile.childImageSharp.gatsbyImageData}
            className="w-12 mr-4 rounded-full bg-neutral-500"
            alt="Erik Törnblom, CEO"
          />
          <div className="flex flex-col items-start">
            <span className="mb-1 text-lg italic font-bold">
              Erik Törnblom, CEO
            </span>
          </div>
        </cite>
      </blockquote>
      <div className=" flex flex-wrap justify-center items-center">
        <div className="w-full md:w-1/2">
          <p className="text-lg">
            The team behind Cargo-Planner is based in Malmö, Sweden - where all
            the development is done. All algorithms are developed in house using
            state of the art solutions. We have been offering our load planning
            solution since 2014, continously growing with customers from all
            over the world. We can truly say that{" "}
            <strong>this is our passion!</strong>
          </p>
          <p className="my-4 text-lg inline-block">
            Want to know more? Listen to our story{" "}
            <a
              className="text-primary"
              href="https://www.projectcargo-weekly.com/2022/02/17/cargo-planner-interview/"
              target="_blank"
            >
              here
            </a>
          </p>
        </div>
        <div className="w-full md:w-1/2 px-3 ">
          <GatsbyImage
            image={data.office.childImageSharp.gatsbyImageData}
            className="shadow-2xl max-h-64"
            alt="Our workplace"
          />
        </div>
      </div>

      <div className="">
        <h1 className="my-4 text-4xl font-medium leading-snug tracking-wider">
          Career
        </h1>

        <p className="text-lg">
          Do you think developing solutions for the logistics industry sounds
          exciting? We are expanding so please{" "}
          <a className="text-primary" href="mailto:career@cargo-planner.com">
            drop us a mail{" "}
          </a>{" "}
          and tell us about you. We have several positions open in both
          development, sales and marketing!
        </p>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  {
    profile: file(relativePath: { eq: "profiles/profile.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300, quality: 80, layout: CONSTRAINED)
      }
    }
    office: file(relativePath: { eq: "theground.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

export default IndexPage
